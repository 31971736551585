//the semi-colon before function invocation is a safety net against concatenated
//scripts and/or other plugins which may not be closed properly.
;(function( $, window, document, undefined ) {

	"use strict";

		var pluginName = 'formWrappers',
			defaults = {
			};

		function Plugin( element, options, id )
		{
			this.id = id;
			this.element = element;
			this.settings = $.extend( {}, defaults, options );
			this._defaults = defaults;
			this._name = pluginName;

			this.init();
		}

		$.extend( Plugin.prototype, {

			init: function()
			{
				var _ = this;

				$('form').each(function(i, el) {
					var form = $(el);
		
					if ( !form.hasClass('wrapped') ) {
						var inputs = _.types();
		
						$.each(inputs, function(i, v) {
							form.find(v).each(function(n, el) {
								var type = v.replace(':not([multiple])', '').replace(':', '');
								_.wrap($(el), type);
							});
		
							_.change(form.find(v), v);
							_.focus(v, v);
							_.blur(v, v);
						});
						form.addClass('wrapped');
					}
				});
			},

			wrap: function(el, type)
			{
				var _ = this;
				var wrapper = $('<div class="'+type+'-wrap"/>');
				el.wrap(wrapper);

				if ( type == 'select' ) {
					var opt = el.find('option:selected');
					if ( opt.prop('disabled') ) {
						el.parent().addClass('placeholder');
					}
					el.before('<ins><span class="selected-text">' + opt.text() + '</span></ins>');
				}

				if ( type == 'file' ) {
					var txt = el.is('[placeholder]') ? el.attr('placeholder') : 'Choose a file…';
					el.attr('placeholder', txt).before('<ins>' + txt + '</ins>');
					el.parent().addClass('placeholder');

					if ( el.is('disabled') ) {
						el.parent().addClass('disabled');
					}
				}

				if ( type == 'checkbox' || type == 'radio' ) {
					var classes = '';
					if ( el.is(':checked') ) {
						classes += ' checked';
					}

					if ( el.is(':disabled') ) {
						classes += ' disabled';
					}

					if ( el.hasClass('hide') ) {
						classes += ' hide';
					}

					el.parent().addClass(classes);
				}
			},

			change: function(inputs, type)
			{
				var _ = this;
				type = type.replace(':not([multiple])', '').replace(':', '');

				inputs.on('change', function() {
					var el = $(this);

					console.log(el);

					if ( type == 'select' ) {
						var opt = '<span class="selected-text">'+el.children('option:selected').text()+'</span>';
						el.parents('.select-wrap').removeClass('placeholder');
						el.siblings('ins').html(opt);
					}

					if ( type == 'file' ) {
						var file_list = el[0].files;

						if ( file_list.length > 0 ) {
							var file = file_list[0];
							el.siblings('ins').text(file.name).parent().removeClass('placeholder');

						} else {
							el.siblings('ins').text(el.attr('placeholder')).parent().addClass('placeholder');
						}
					}

					if ( type == 'checkbox' ) {
						if ( el.prop('checked') ) {
							el.parent().addClass('checked');

						} else {
							el.parent().removeClass('checked');
						}
					}

					if ( type == 'radio' ) {
						var name = el.attr('name');

						if (el.prop('checked')) {
							$('input[name="'+name+'"]').parent().removeClass('checked');
							el.parent().addClass('checked');
						}
					}
				});
			},

			focus: function(inputs, type) {
				var _ = this;
				$('body').on('focus', inputs, function() {
					var el = $(this);
					el.parent().addClass('focused');
				});
			},
		
			blur: function(inputs, type) {
				var _ = this;
				$('body').on('blur', inputs, function() {
					var el = $(this);
					el.parent().removeClass('focused');
				});
			},
		
			types: function() {
				return ['select:not([multiple])', ':checkbox', ':radio', ':file'];
			}

		} );

		// A really lightweight plugin wrapper around the constructor,
		// preventing against multiple instantiations
		$.fn[ pluginName ] = function( options ) {
		    return this.each( function() {
		        if ( !$.data( this, "plugin_" + pluginName ) ) {
		            $.data( this, "plugin_" +
		                pluginName, new Plugin( this, options ) );
		        }
		    } );
		};
		
})( jQuery, window, document );