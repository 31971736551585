if(typeof($) === 'undefined') {
    var $ = jQuery;
}

var b = document.documentElement;
	b.className = b.className.replace('no-js', 'js');
	b.setAttribute("data-useragent", navigator.userAgent.toLowerCase());
	b.setAttribute("data-platform", navigator.platform.toLowerCase());

var $html = $('html');
var ua = ($html.data('useragent')),
    pf = ($html.data('platform'));


function is(string)
{
    return ua.indexOf(string) > -1
}

var browser = {
    isIE            : is('msie') || is('trident/7.0'),
    isIE7           : is('msie 7.0'),
    isIE8           : is('msie 8.0'),
    isIE9           : is('msie 9.0'),
    isIE10          : is('msie 10'),
    isIE11          : is('rv:11') && is('trident/7.0'),
    isEdge          : is('edge'),
    isChrome        : is('chrome') && !is('edge'),
    isSafari        : is('safari') && !is('chrome') && !is('edge'),
    isFirefox       : is('firefox') && !is('edge'),
    isAndroidChrome : is('android') && is('chrome'),
    isAndroidDefault: is('android') && !is('chrome'),
    isWin7          : is('windows nt 6.1'),
    isWin8          : is('windows nt 6.2'),
    isWindows       : pf.indexOf('win32') > -1,
    isWebkit        : is('webkit') && !is('edge'),
    isIPad          : is('ipad'),
    isIPadChrome    : is('ipad') && is('crios'),
    isIPhone        : is('iphone'),
    isIPhoneChrome  : is('iphone') && is('crios'),
    isAndroid       : is('android'),
    isIOS           : is('iphone') || is('ipad')
}

for (var title in browser) {
    var helperClass = title.slice(2).toLowerCase();

    if (browser[title]) {
        $html.addClass(helperClass);
    }
}